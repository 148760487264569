$primary-color:#ffd700;
@import 'animate.css';

@font-face {
  font-family: 'Helvetica Neue';

 src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}
@font-face {
  font-family: 'La Belle Aurore';

 src: url('./assets/fonts/LaBelleAurore.woff2') format('ttf');
}
@font-face {
  font-family: 'Coolvetica';

 src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('ttf');
}
input,
textarea{
  font-family: "Helvetica Neue";
}
.App{
  display: flex;
  flex-direction: column;
}