

.main-block-home{
display: flex;
flex-direction: row;
color: white;

background-image: url("./assets/images/Rect Light.svg");
background-repeat: repeat-y;
background-size: cover;
height: 100vh;
width: 100%;
overflow-x: auto;

@media screen and (min-width: 720px) {
   overflow-x: hidden;
}
}

.name-block-home{
    height: 150px;
    max-height: 150px;
    text-align: left;
   margin-bottom: 32vh;
    color: white;
   font-size: 32px;
  font-weight: 300;
  margin-top: 14vh;
  margin-left: 2vw;
    border-radius: 10px;
    max-width: 780px;
    
    text-align: center;
min-width: 780px;
}



.name-home-programming{
    margin-left: 10vw;
    align-self: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
    margin-top: 8vh;
    position: relative;

}
.name-home-title{
    
    align-self: left;
    align-content: left;
    justify-content: left;
    justify-self: left;
    text-align: left;
    margin-bottom: 2vh;

 
}

.name-home{
    min-height: 580px;
}
.Below-Link{
    align-self: left;
    align-content: left;
    justify-content: left;
    justify-self: left;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 2vh;
}
.name-home-buttons{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-left: 77vw;
        
    
}
#name{

        font-weight:    bolder;
        color:"#FFFCF9";
     
    
}
#engineer{
    font-size: 24px;
    color:"#FFFCF9";
      font-weight: bolder;
}


/* now make the following page mobile friendly by resizing the picture and making the fonts smaller */
@media screen and (max-width:500px) {
    #rick{
        max-height: 0px;
       max-width: 0px;
    }
    #threed{
        max-height: 0px;
       max-width: 0px;
    }
}

@media screen and (max-width: 768px) {
    #rick{
        max-height: 200px;
        margin-left: -25vw;
    }
    #name{
        font-size: 24px;
       
        color:"#FFFCF9";
          font-weight: bolder;
    }
    #engineer{
        font-size: 18px;
        color:"#FFFCF9";
          font-weight: bolder;
    }
    .name-home-programming{
        margin-left: 0vw;
        align-self: center;
        align-content: center;
        justify-content: center;
        justify-self: center;
        margin-top: 8vh;
        position: relative;
    
    }
    .name-home-title{
        
        align-self: left;
        align-content: left;
        justify-content: left;
        justify-self: left;
        text-align: left;
        margin-bottom: 2vh;
    
     
    }
    
    .name-home{
        min-height: 520px;
        
    }
    .Below-Link{
        align-self: left;
        align-content: left;
        justify-content: left;
        justify-self: left;
        text-align: left;
        font-family: Arial, Helvetica, sans-serif;
        height: 44px;
        width: 100%;
        margin-top: 5vh;
        @media  screen and (max-width: 768px) {
            font-size: 12px;
            
            
        }
        
    }
    .name-home-buttons{
        display: flex;
        flex-direction: row;
        margin-left: 50vw;
        min-height: 40px;
        
        
        
    }
    .name-block-home{
        height: 150px;
        max-height: 150px;
        text-align: left;
       margin-bottom: 32vh;
        color: white;
       font-size: 18px;
      font-weight: 300;
      margin-top: 14vh;
      margin-left: 2vw;
        border-radius: 10px;
        max-width: 87vw;
        
        text-align: center;
    min-width: 300px;
    }
    #schwifty{
        min-width:110px;
   
    }
    .main-block-home{
        display: flex;
        flex-direction: row;
        color: white;
    
        background-image: url("./assets/images/Rect Light.svg");
        background-repeat: repeat-y;
        background-size: cover;
        max-height: 100vh;
        width: 100vw;
        overflow-x: auto;
      
    }
    .name-home-programming{
        margin-left: 0vw;
        align-self: center;
        align-content: center;
        justify-content: center;
        justify-self: center;
        margin-top: 8vh;
        position: relative;
    
    }
    .name-home-title{
        
        align-self: left;
        align-content: left;
        justify-content: left;
        justify-self: left;
        text-align: left;
        margin-bottom: 2vh;}
    
       
 
    }