.main-404 {
  display: flex;
  flex-direction: column;
  background-color: #07004d;
  height: 100vh;
  color: white;
  justify-content: center;
  align-items: center;
}

#link-404 {
  color: white;
  font-size: 24px;
  font-weight: 700;
}

.text-404 {
  font-size: 24px;
  font-weight: 500;
}
.morty-pickle {
  width: 360px;
  height: 466px;
}
